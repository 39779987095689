<template>
    <WidgetWrapper :widget="widget">
        <InvestProjectsWidget />
    </WidgetWrapper>
</template>

<script>
import InvestProjectsWidget from './components/InvestProjects'
import WidgetWrapper from '../WidgetWrapper.vue'

export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        InvestProjectsWidget,
        WidgetWrapper
    },
}
</script>